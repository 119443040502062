@import "~materialize-css/dist/css/materialize.min.css";
.login-wrap {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #1e1e1e;
}
.card.main-color {
    background: #363636;
    position: absolute;
    left: 50%;
    top: 25%;
    transform: translateX(-50%);
    width: 480px;
}
.card-title>span {
    color:#2e84fb; font-weight:600;
}
.card .card-title {
    font-size: 22px;
    font-weight: 100;
}
input:not([type]):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #2e84fb;
    box-shadow: 0 1px 0 0 #2e84fb;
}
input:not([type]):focus:not([readonly])+label, input[type=text]:not(.browser-default):focus:not([readonly])+label, input[type=password]:not(.browser-default):focus:not([readonly])+label, input[type=email]:not(.browser-default):focus:not([readonly])+label, input[type=url]:not(.browser-default):focus:not([readonly])+label, input[type=time]:not(.browser-default):focus:not([readonly])+label, input[type=date]:not(.browser-default):focus:not([readonly])+label, input[type=datetime]:not(.browser-default):focus:not([readonly])+label, input[type=datetime-local]:not(.browser-default):focus:not([readonly])+label, input[type=tel]:not(.browser-default):focus:not([readonly])+label, input[type=number]:not(.browser-default):focus:not([readonly])+label, input[type=search]:not(.browser-default):focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
    color: #2e84fb;
}
.input-field>label {
    color: #ffffff;
}
button.btn.main-b {
    background: #009b06;
    border-radius: 7px;
}
header#header {
    position: fixed;
    left: 0px;
    top: 0;
    height: 54px;
    padding: 18px 35px;
    transition: .5s ease;
    width: 100%;
    overflow: hidden;
    font-weight: 500;
    font-size: 15px;
    box-shadow: 0 0 10px 0px #00000042;
    background: #363636;
    z-index: 999;
    display: flex;
}
.logotip {
    width: max-content;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #fff;
}

.logotip>span:first-child {
    color: #2e84fb;
    font-weight: 900;
}
.logotip>span:last-child {
    opacity: 1;
}
header#header .menu-label {
    opacity: 1;
    color: #fff;
}
.main-menu {
    display: flex;
    margin-left: 25px;
}
.menu-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 10px;
}
.menu-item:hover {
    color: #2e84fb;
}
.menu-item:hover svg {
    color: #2e84fb;
}
.menu-icon {
    padding-left: 5px;
    position: relative;
}
.menu-label {
    margin-left: 7px;
    position: relative;
}


.workspace {
    padding: 20px;
    opacity: 1;
    padding-top: 70px;
    background: #1e1e1e;
}
.tab {
    width: 100%;
    height: calc(100% - 40px);
    padding: 10px 20px;
    opacity:1;
    transition: .3s ease;
}
.tab.active {
    opacity:1;
}
.tab-title {
    font-weight: 300;
    font-size: 32px;
    color: #fff;
    cursor:default;
}

.menu-label:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    border-bottom: 2px solid #2e84fb;
    transition: .2s linear;
    width: 0%;
    height: 0px;
}
.menu-item.active>.menu-label:after {
    width: 100%;
}
.site-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.site-card {
    display: flex;
    margin: 5px 5px;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    border-radius: 12px;
    transition: .3s ease;
    overflow: hidden;
    position: relative;
    color: #fff;
    background: #363636;
    width: 320px;
    padding: 5px;
    height: 60px;
}

.site-card>img {
    width: 100%;
    height: 182px;
    transition: .3s ease;
}

.minidash {
    display: flex;
    justify-content: space-around;
    font-weight: 600;
    font-size: 13px;
    margin-top: 5px;
}

.site-card>a {
    color: #fff;
    background: #2e84fb;
    width: 60px;
    font-size: 14px;
    text-align: center;
    margin-top: 5px;
    padding: 5px 3px;
    border: 1px solid #2e84fb;
    border-radius: 7px;
    font-weight: 300;
    transition: .3s ease;
    position: relative;
    display: inline-block;
    margin-left: 7px;
}


.lamp {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    position: absolute;
    right: 5px;
    bottom: 5px;
    box-shadow: 0 0 6px #00000047;
}
.flicker {
    animation: flick 1s ease infinite;
}
a.link-to-details {
  color: white;
  background: #2e84fb;
    width: 100px;
  display: block;
  font-size: 14px;
  text-align: center;
    padding: 5px 3px;
  border: 1px solid #2e84fb;
  border-radius: 7px;
  font-weight: 300;
  transition: .3s ease;
  z-index: 5;
    display: inline-block;
}

.site-name {
    font-weight: 600;
    margin: 0 3px 5px;
    font-size: 14px;
    overflow: hidden;
    height: 23px;
}
.panel {
    width: calc(100% - 20px);
    padding-left: 0px;
}
.sites-table {
    width: 100%;
    max-width: 1000px;
    background: #fff;
    border-radius: 5px;
    margin-left: 25px;
    overflow: hidden;
    margin-top: 15px;
    max-height: 600px;
    position: relative;
}

.table-row {
    display: flex;
    padding: 5px 15px;
    border-bottom: 1px dashed #ccc;
    align-items: center;
    position: relative;
}

.site-name-col {
    width: 25%;
    font-weight: 600;
    margin-left: 12px;
}

.tickets-col {
    width: 20%;
    text-align: center;
}

.button-col {
    width: 20%;
    display: flex;
    justify-content: end;
    margin-left: auto;
}

.table-header {
    background: #fff;
    font-weight: 600;
    color: #1e1e1e;
    box-shadow: 0px 3px 20px 0px #00000029;
}

.main {
    background: #1e1e1e;
    min-height: 100vh;
}

.table-content {
    overflow-y: scroll;
    max-height: 555px;
}

.table-content>div {border-bottom: 1px solid #00000021;transition: .3s ease;align-items: center;}

.table-content>div:hover {
    background: #0011ff0d;
}

.site-setup {
    color: #2e84fb;
    background: #fff0;
    width: fit-content;
    margin: auto;
    padding: 3px 20px;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s ease;
    border: 1px solid #2e84fb;
}

.site-setup:hover {
    background: #2e84fb;
    color: #fff;
}
@keyframes flick {
    0% {
    opacity:1;
    }
    50% {
    opacity:0;
    }
    100% {
    opacity:1;
    }
}
.popup-wrapper.opened {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000e6;
    z-index: 9999;
}

.popup-settings {
    position: absolute;
    background: #fff;
    left: 50%;
    top: 17%;
    transform: translateX(-50%);
    max-width: 900px;
    border-radius: 5px;
    padding: 7px;
    width: 100%;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 600;
}

.popup-header>span {
    color: #fff;
    font-weight: 300;
    border: 1px solid red;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    background: #f00;
    transition: .3s ease;
}

.popup-header>span:hover {
    background: #fff;
    color: #F00;
}

.popup-form {
    display: flex;
    flex-wrap: wrap;
}

.input-row {
    width: 45%;
    margin: 0 1%;
    margin-top: 20px;
    position: relative;
    border: 1px solid #2e84fb;
    height: 40px;
    border-radius: 5px;
    min-width: 300px;
}

.buttons-panel {
    width: 100%;
    display: flex;
    margin: 15px 0;
    padding: 0 1%;
}

.setting-label {
    position: absolute;
    top: -9px;
    left: 10px;
    font-size: 11px;
    background: #fff;
    color: #2e84fb;
    font-weight: 500;
    padding: 0 3px;
}

.input-row>input {
    border: none;
    outline: none;
    box-shadow: none;
    height: 38px;
    width: 100%;
    margin: 0!important;
    padding: 0 10px;
    border-radius: 7px;
    display: block;
    color: #000000;
    font-weight: 200;
    font-size: 14px;
}

.save-settings {
    padding: 5px 15px;
    color: #fff;
    border: 1px solid green;
    background: green;
    margin-right: 12px;
    border-radius: 3px;
    transition: .3s ease;
    cursor: pointer;
}

.save-settings:hover {
    background: #fff;
    color: green;
}
.textarea-row {
    width: 92%;
    margin: 20px 1% 0;
    position: relative;
    border: 1px solid #2e84fb;
    height: 200px;
    border-radius: 5px;
    min-width: 300px;
}

.textarea-row>textarea {
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
    padding: 15px;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 13px;
}
.chart-panel {display: flex;margin: 10px 20px;}

.c-title {font-size: 16px;margin-bottom: 7px;}

.m-chart {margin: 0 12px;}


.btn-panel {
    padding: 0;
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 5px;
}
.host {
    width: 50%;
    font-size: 13px;
}
.btn-panel>a {margin: 0 10px;}

.new-site {
    position: absolute;
    right: 6px;
    top: 6px;
    border: 1px solid green;
    color: green;
    padding: 3px 15px;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s ease;
}

.new-site:hover {
    color: #fff;
    background: green;
}

.search-row>input {
    background: #fff!important;
    width: 100%;
    max-width: 650px;
    color: #000;
    border: none!important;
    outline: none!important;
    padding: 0px 15px!important;
    margin: 0!important;
}

.search-row {
    display: flex;
    padding: 25px 15px;
}

.start-search {
    color: #fff;
    background: #2e84fb;
    width: 100px;
    text-align: center;
    align-self: center;
    padding: 11px 15px;
    font-size: 15px;
    cursor: pointer;
}
.results-box {
    width: 100%;
    max-width: 780px;
    background: #fff;
    margin: 0 15px;
    padding: 10px 20px;
    padding-right: 0;
    overflow-y: scroll;
    height: 60vh;
    transition: .3s ease;
}

.stb-results {
    display: flex;
    color: #000;
    padding: 5px 0;
    border-bottom: 1px dashed #ccc;
}

.stb-time {
    margin-right: 15px;
    min-width: 105px;
}

.sites-box {
    display: flex;
    width: 100%;
    max-width: 780px;
    margin: 15px;
}
.sites-box>select {
    display:block!important;
}
.upload-btn {
    width: inherit;
    margin: 0 4px;
    background: red;
    cursor: not-allowed;
}
.sites-list {
    background: #fff;
    border-radius: 5px;
    width: 100%;
    max-width: 1500px;
    margin-left: 15px;
    margin-top: 15px;
    padding: 10px 0px;
}

.site-edit {
    color: #fff;
    background: green;
    border: 1px solid green;
    border-radius: 3px;
    padding: 1px 10px;
    transition: .3s ease;
    cursor: pointer;
    text-align:center;
}

.site-edit:hover {
    background: #fff;
    color: green;
}

.site-delete {
    color: red;
    background: #fff;
    border: 1px solid red;
    border-radius: 3px;
    padding: 1px 10px;
    transition: .3s ease;
    cursor: pointer;
    margin-left: 10px;
}

.site-delete:hover {
    background: #f00;
    color: #fff;
}
.table-row input {
    border: 1px solid #242424!important;
    font-size: 15px!important;
    height: 36px!important;
    margin-right: 10px!important;
    padding: 0 10px!important;
    border-radius: 3px!important;
    width: 20%!important;
}
input.site-name-col {
     width: 30%!important;
}

.table-row input:focus {
    border: 1px solid green!important;
    border-bottom: 1px solid green!important;
    outline: none!important;
    box-shadow: none!important;
}
.lamp.left {
    left: 5px;
    top: 11px;
}
.popup-w {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background: #000000c9;
}

.delete-popup {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    top: 42%;
    padding: 10px 15px;
    border-radius: 10px;
    padding-top: 15px;
    font-weight: 600;
}

.btns {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.choice {
    color: #fff;
    background: green;
    width: 80px;
    height: 30px;
    text-align: center;
    padding: 3px 0;
    border-radius: 3px;
    border: 1px solid green;
    cursor: pointer;
    transition: .3s ease;
    margin-left: 15px;
}

.choice:hover {
    background: #fff;
    color: green;
}
.choice-no {
    color: #fff;
    background: red;
    width: 80px;
    height: 30px;
    text-align: center;
    padding: 3px 0;
    border-radius: 3px;
    border: 1px solid red;
    cursor: pointer;
    transition: .3s ease;
}

.choice-no:hover {
    background: #fff;
    color: red;
}
.popup-w.deactive {
    display: none;
}
.delete-popup input {
    border: 1px solid #000000!important;
    font-size: 15px!important;
    height: 36px!important;
    margin-right: 10px!important;
    padding: 0 10px!important;
    border-radius: 3px!important;
    text-align: left;
}
.delete-popup input:focus {
    border: 1px solid green!important;
    border-bottom: 1px solid green!important;
    outline: none!important;
    box-shadow: none!important;
}
.delete-popup input {
    width: 97%!important;
}

.add-new-site {
    color: #fff;
    background: green;
    width: fit-content;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    margin-left: 30px;
    margin-top: 0px;
}

.tab-title.sett {
    display: inline-block;
    padding-top: 0px;
}
.counter-wrapper {
    width: 200px;
    background: #fff;
    height: 240px;
    border-radius: 10px;
    margin: 15px;
    margin-right: 0;
    position: relative;
    overflow: hidden;
    transition: .5s ease;
}

.dev-percent {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
}
td.sites-table-col {
    cursor: pointer;
    min-width: 110px;
    position: relative;
}
td.sites-table-col.sorted {border-bottom: 2px solid red;}
.counts-row {
    display: flex;
}

.devices {
    display: flex;
    color: #000;
    justify-content: space-evenly;
    font-weight: 600;
    font-size: 15px;
    margin-top: 15px;
    text-align: center;
}

.counter-title {
    color: #000;
    font-weight: 700;
    font-size: 18px;
    margin-top: 5px;
    text-align: center;
}

.count-circle {
    border-radius: 100%;
    border: 5px solid #2e84fb;
    width: 140px;
    height: 140px;
    margin: auto;
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 124px;
    color: #2e84fb;
}

.counter-wrapper.blurred {
    /* filter: brightness(0.8); */
}

.counter-wrapper>svg {
    position: absolute;
    z-index: 5;
    left: 50%;
    top: 42px;
    transform: translateX(-50%);
}

.counter-wrapper.blurred>.count-circle {
    border-color: transparent;
}
.stat-container {
    width: 100%;
}

.chart-wrapper-i {
    width: 1300px;
    background: #fff;
    border-radius: 7px;
    margin-left: 16px;
    height: 628px;
    margin-top: 15px;
    padding-top: 10px;
    position: relative;
}

.chart-title-i {
    color: #000;
    font-weight: 700;
    font-size: 18px;
    margin-left: 24px;
    margin-bottom: 17px;
}

.chart-circle {
    font-size: 14px;
    border-color: transparent;
    position: absolute;
    left: 50%;
    top: 35%;
    transform: translateX(-50%);
}

.chart-wrapper-i>svg {
        position: absolute;
        left: 50%;
        top: 36%;
        transform: translateX(-50%);
}
table.sites-table-wrapper {
    background: #fff;
    border-radius: 8px;
    margin-top: 15px;
    margin-left: 17px;
    color: #000;
}

td.sites-table-col {
    padding: 7px 5px;
    padding-left: 12px;
    font-size: 14px;
}

tr.sites-table-row {
    border-bottom: 1px dashed #ccc;
}

thead .sites-table-col {font-size: 15px;}

thead.sites-table-header {
    font-weight: 600;
    font-size: 15px;
    position: sticky;
    top: 54px;
    background: #fff;
    box-shadow: 0 0 20px 4px #0000003b;
}

tr.sites-table-row {
    transition: .3s ease;
}

tr.sites-table-row:hover {
    background: #fb2e2e26;
}

td.sites-table-col {
    transition: .3s ease;
}

td.sites-table-col:hover {
    background: #ff00005c;
}
.trh, .tri,.trj, .trk, .trl, .trm, .trn, .tro, .trp, .trq, .trr, .trc {
    text-align: center;
}
.counts-row {
    display: flex;
}

.calendar-wrapper {
    width: 340px;
    background: #fff;
    height: 340px;
    border-radius: 10px;
    margin: 15px 0 15px 15px;
    position: relative;
    overflow: hidden;
    transition: .5s ease;
}
.performers-columns {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 20px auto;
    justify-content: space-around;
}
.performers-columns>* {
    width: 48%;
    background: #fff;
    padding: 5px 20px;
    border-radius: 10px;
    position: relative;
}
.performers-list {
    height: 800px;
    overflow-y: scroll;
}
label.prow-tnw, label.prow-stb {
    display: flex;
    width: 100%;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    height: 34px;
    border-bottom: 1px dotted #ccc;
    align-items: center;
    user-select: none;
    cursor: pointer;
}

label.prow-tnw>input, label.prow-stb>input {
    position: static!important;
    width: 16px;
    height: 16px;
    opacity: 1!important;
    margin-right: 10px;
}
.start-merge {
    margin: 25px auto;
    width: fit-content;
    background: green;
    color: #fff;
    cursor: pointer;
    padding: 10px 50px;
    border-radius: 5px;
}
label.prow-stb.merged::after {
    content: 'СКЛЕЕН';
    color: green;
    margin-left: 7px;
    border: 1px solid green;
    padding: 1px 3px;
    font-size: 10px;
    border-radius: 3px;
    line-height: 11px;
    font-weight: 700;
}
.merging-tooltip {
    position: absolute;
    left: 25px;
    top: 60px;
    background: #fff;
    padding: 7px 15px;
    border-radius: 3px;
    box-shadow: 0px 2px 10px 5px #00000036;
    transition: .2s ease;
    z-index: 3;
    cursor: pointer;
}

.merging-tooltip::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    left: 25%;
    top: -5px;
    transform: rotate(45deg);
    box-shadow: -4px -4px 20px 4px #00000036;
    z-index: 1;
}
.merging-tooltip::after {
    content: '';
    position: absolute;
    width: calc(100% - 10px);
    height: 10px;
    background: #fff;
    left: 5px;
    top: 0px;
    z-index: 1;
}