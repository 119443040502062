
.chart-wrapper {margin: 25px 15px; width: 42%;}

.inspector {
    width: 100%;
}

.charts {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 10px;
    padding: 40px 10px;
}

.chart-title {
    margin-bottom: 20px;
    color: #000;
    font-size: 21px;
    margin-left: 50px;
}

.top-block {
    display: flex;
    margin: 20px 65px;
}

.top-block>div:first-child {
    width: 400px;
    margin-right: 30px;
}

.top-block img {
    width: 400px;
}

.mini-table li {
    color: #fff;
    font-size: 15px;
    padding: 7px 10px;
    border-bottom: 1px dashed #c1c1c1;
}

.tab-title {
    font-weight: 600;
    color: #fff;
    font-size: 26px;
    margin-left: 16px;
    cursor: default;
}
.ranges {
    width: 100%;
    display: flex;
    margin-left: 65px;
}

.period {
    margin-right: 10px;
    border: 1px solid #2c57d1;
    padding: 5px 15px;
    border-radius: 5px;
    color: #1f4dce;
    transition: .3s ease;
    cursor: pointer;
}

.period.active {
    color: #fff;
    background: #1f4dce;
}